<template>
  <div>
    <styledTable :data="draftProducts">
      <el-table-column prop="id" :label="$t('DraftPageIDColumnTitle')">
      </el-table-column>
      <el-table-column
        prop="providerId"
        :label="$t('DraftPageProviderIdColumnTitle')"
      >
      </el-table-column>
      <el-table-column prop="price" :label="$t('DraftPagePriceColumnTitle')">
      </el-table-column>
      <el-table-column prop="code" :label="$t('DraftPageCodeColumnTitle')">
      </el-table-column>
      <el-table-column prop="name" :label="$t('DraftPageNameColumnTitle')">
      </el-table-column>
      <el-table-column
        prop="quantity"
        :label="$t('DraftPageQuantityColumnTitle')"
      >
      </el-table-column>
      <el-table-column
        prop="threshold"
        :label="$t('DraftPageThresholdColumnTitle')"
      >
      </el-table-column>
      <el-table-column
        prop="Operations"
        :label="$t('DraftPageOperationsColumnTitle')"
      >
        <template slot-scope="scope">
          <div>
            <el-button
              type="primary"
              size="default"
              @click="addDraft(scope.row)"
              >{{ $t("DraftPageAddraftBtn") }}</el-button
            >
          </div>
        </template>
      </el-table-column>
    </styledTable>
    <div class="u-display-flex u-justify-content--center u-top-margin--2x">
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :total="productsPagination.totalResultsCount"
        :current-page="
          productsPagination.nextPage ? productsPagination.nextPage - 1 : 1
        "
        :page-size="50"
        @current-change="loadMoreProducts"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import styledTable from "../../components/dataContainers/styledTable";
import { productsService } from "../../services/products.service";
// import { mapGetters } from "vuex";
export default {
  name: "Draft Products",

  components: {
    styledTable,
  },
  data() {
    return {
      draftProducts: [],
      productsPagination: {},
    };
  },
  created() {
    this.getDraftProducts();
  },
  computed: {
    page() {
      return this.$route.query.page;
    },
  },
  methods: {
    getDraftProducts(page = 1) {
      this.$loading();
      productsService
        .getDraftProducts({}, page)
        .then((data) => {
          this.draftProducts = data.products;
          this.productsPagination = data.pagination;
        })
        .finally(() => {
          this.$loading().close();
        });
    },
    addDraft(product) {
      this.$router.push({
        name: "addProduct",
        query: {
          id: product.id,
          name: product.name,
        },
      });
    },
    loadMoreProducts(page) {
      this.getDraftProducts(page);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>